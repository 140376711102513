import React from "react";
import { PartnerPortalTooltipProps } from "../helpers/types";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  alt: "Tooltip";
  type: "button";
}

function Button({ alt, type, ...props }: ButtonProps) {
  return <button type="button" {...props} />;
}

const PartnerPortalTooltip = ({
  tooltip,
  children,
  position,
  externalTooltipHandler,
}: PartnerPortalTooltipProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (externalTooltipHandler || externalTooltipHandler === false) {
      setShowTooltip(externalTooltipHandler);
    }
  }, [externalTooltipHandler]);

  const handleShowTooltip = (show: boolean) => {
    if (!externalTooltipHandler && externalTooltipHandler !== false) {
      setShowTooltip(show);
    }
  };

  return (
    <span
      className={
        position === "table"
          ? "pp-input__tooltip-button-container-table"
          : "pp-input__tooltip-button-container"
      }
    >
      {/* <button
        className="pp-input__tooltip-icon"
        alt="Tooltip"
        type="button"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      /> */}
      {children ? (
        <button
          onMouseEnter={() => handleShowTooltip(true)}
          onMouseLeave={() => handleShowTooltip(false)}
        >
          {children}
        </button>
      ) : (
        <Button
          className="pp-input__tooltip-icon"
          alt="Tooltip"
          type="button"
          onMouseEnter={() => handleShowTooltip(true)}
          onMouseLeave={() => handleShowTooltip(false)}
        />
      )}
      {showTooltip ? (
        <div
          className={`
          pp-input__tooltip-container 
          ${position === "left" ? "pp-input__tooltip-container-left" : ""} 
          ${position === "right" ? "pp-input__tooltip-container-right" : ""} 
          ${position === "table" ? "pp-input__tooltip-container-table pp-input__tooltip-container-right" : ""} 
          ${position === "dropdown" ? "pp-input__tooltip-container-dropdown" : ""}
        `}
        >
          <div className="pp-input__tooltip">{tooltip}</div>
        </div>
      ) : null}
    </span>
  );
};
export default PartnerPortalTooltip;
