import { useState } from "react";

// Define a type for the options parameter in fetchData
interface FetchOptions extends RequestInit {}

// Define the return type for the useFetchApi hook
interface FetchApiReturnType<T> {
  data: T | null;
  error: string | null;
  loading: boolean;
  fetchData: (url: string, options?: FetchOptions) => Promise<void>;
}

function useFetchApi<T>(): FetchApiReturnType<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (
    url: string,
    options: FetchOptions = { method: "GET" },
  ) => {
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        // console.log('response',response.body);
        const result = (await response.json()) as T;
        let msg = `HTTP error! Status: ${response.status}`;
        if (result && Object.keys(result).length > 0) {
          for (const key in result) {
            if (result.hasOwnProperty(key)) {
              msg = `${key} ${result[key][0]}`;
              break;
            }
          }
        }
        throw new Error(msg);
      }
      const result = (await response.json()) as T;
      setData(result);
      setError(null);
    } catch (e) {
      setError(e.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
}

export default useFetchApi;
