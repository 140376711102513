import React, { useState } from "react";
import DollarIcon from "../../../assets/images/enterprise/inkind/icons/dollar-icon.svg";
import CheckIcon from "../../../assets/images/enterprise/inkind/icons/check-icon.svg";
import LargeCheckIcon from "../../../assets/images/enterprise/inkind/icons/large-check-icon.svg";
import SuccessToast from "../../ui/molecules/SuccessToast";

const EmailClaimSuccess = ({
  ikname,
  value,
  inkindStateDomain,
  handleAmplitude,
  userEmail,
}) => {
  const [showToast, setShowToast] = useState(true);
  const android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
  const url = `${inkindStateDomain}/rewards`;
  const handleAppLaunchClick = async () => {
    await handleAmplitude("click_open_inKind_app", {});
    if (!android) {
      setTimeout(function () {
        window.location = url;
      }, 500);
    } else {
      let appLinkTimeout = setTimeout(function () {
        window.location = url;
      }, 500);
      let clearAppLinkTimeout = function () {
        if (appLinkTimeout) {
          clearTimeout(appLinkTimeout);
          appLinkTimeout = null;
        }
      };
      window.addEventListener("blur", function () {
        if (clearAppLinkTimeout) {
          clearAppLinkTimeout();
        }
      });
    }
  };

  return (
    <div className="email-claim-success">
      <SuccessToast
        className="email-claim-success-toast"
        message="Offer succesfully claimed"
        subMessage={`Your ${value} offer has been added your Rewards in the inKind app and will be auto-applied at checkout.`}
        show={showToast}
        setShow={setShowToast}
        icon={LargeCheckIcon}
      />
      <div className="email-claim-success-email">
        <img src={CheckIcon} alt="Check Icon" />
        <span>
          Offer added to your <span className="highlight">{userEmail}</span>{" "}
          inKind account
        </span>
      </div>
      <button
        onClick={handleAppLaunchClick}
        type="button"
        className="email-claim-success-button"
      >
        <img src={DollarIcon} alt="Dollar Icon" />
        <span>Open in {ikname}</span>
      </button>
      <div className="email-claim-success-text">
        Pay and redeem through the inKind app
      </div>
      <div className="email-claim-success-sub-text">
        Your offer will be auto-applied as you checkout. Paying through inKind
        is easy and faster than paying with card.
      </div>
    </div>
  );
};

export default EmailClaimSuccess;
