import React, { useEffect } from "react";
import EmptyImage from "../atoms/EmptyImage";
import { useDropzone } from "react-dropzone";
import forOwn from "lodash-es/forOwn";
import { AddImageProps, CloudinaryProps } from "../../helpers/types";

async function uploadFile(
  file: Record<string, any>,
  cloudinary: CloudinaryProps,
) {
  const imgurl = `https://api.cloudinary.com/v1_1/${cloudinary.name}/image/upload`;

  const data = new FormData();

  data.append("file", file);

  forOwn(cloudinary, (v, k) => {
    data.append(k, v);
  });

  const config = {
    method: "POST",
    body: data,
  };

  const response = await fetch(imgurl, config);

  return await response.json();
}

const AddImage = ({
  label,
  cloudinary,
  values,
  setFieldValue,
  name,
  isMultipleArr,
  transparent,
  triggerClear,
  square,
  showError,
}: AddImageProps) => {
  const cloudPath = `https://res-5.cloudinary.com/${cloudinary.name}/image/upload/c_fill,w_116`;

  const getImage = () => {
    if (name === "pass-promo-img" && !!values) {
      return `${cloudPath}/${values.path}`;
    } else if (values?.[name] && Object.keys(values?.[name]).length > 0) {
      return `${cloudPath}/${values[name].path}`;
    } else {
      return null;
    }
  };

  const [image, setImage] = React.useState(getImage());
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  useEffect(() => {
    const image = values?.[name as string];
    if (!image) setImage(null);
    if (image?.path) setImage(getImage());
  }, [values?.[name as string]]);

  function handleDelete() {
    if (!isMultipleArr) {
      setFieldValue(name, {});
    } else {
      const newMultipleArr = isMultipleArr;
      for (let i = 0; i < newMultipleArr.length; i++) {
        if (newMultipleArr[i].url === image) {
          newMultipleArr.splice(i, 1);
        }
      }
    }
    setImage(null);
    setFileName("");
  }

  React.useEffect(() => {
    if (!!triggerClear) {
      handleDelete();
    }
  }, [triggerClear]);

  const validateImage = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const isSquare = img.width === img.height;
          const meetsSize = img.width >= 512 && img.height >= 512;

          if (square && (!isSquare || !meetsSize)) {
            reject("Square images must be at least 512x512 pixels.");
          } else if (!square && file.size > 1 * 1024 * 1024) {
            reject("Non-square images must be under 1 MB.");
          } else {
            resolve();
          }
        };
        img.onerror = () => reject("Invalid image file.");
        img.src = reader.result as string;
      };
      reader.onerror = () => reject("Error reading file.");
      reader.readAsDataURL(file);
    });
  };

  const { getInputProps, open } = useDropzone({
    accept: !!transparent
      ? { "image/svg+xml": [], "image/webp": [], "image/png": [] }
      : { "image/jpeg": [], "image/png": [] },
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        try {
          setIsLoading(true);
          setErrorMessage("");
          await validateImage(file);
          const result = await uploadFile(file, cloudinary);
          if (!isMultipleArr) {
            setFieldValue(name, result);
          } else {
            if (!!image) {
              handleDelete();
            }
            const newIsMultipleArr = isMultipleArr;
            newIsMultipleArr.push(result);
          }
          setImage(result.url);
          setFileName(result.original_filename);
        } catch (error) {
          setErrorMessage(error as string);
        } finally {
          setIsLoading(false);
        }
      });
    },
  });

  return (
    <div className="image-upload-container">
      <div className="my-2 radio-label-top">{label}</div>

      <div className="image-select-mass-gifting">
        {image ? (
          <div className="uploaded-image-container">
            <img src={image} alt="preview of upload" />
            <span className="delete-img">
              <button onClick={handleDelete}>
                <svg
                  width="10px"
                  height="10px"
                  viewBox="0 0 10 10"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Checkout-screens_V4"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Gift-Checkout---1-Copy-10"
                      transform="translate(-313.000000, -814.000000)"
                      fill="#C4C4C4"
                      fillRule="nonzero"
                    >
                      <g
                        id="Group-3"
                        transform="translate(251.000000, 434.000000)"
                      >
                        <g
                          id="Group-19"
                          transform="translate(0.000000, 356.000000)"
                        >
                          <g
                            id="Group-3"
                            transform="translate(16.000000, 16.000000)"
                          >
                            <path
                              d="M55.5270816,8.47291835 L55.5270816,8.47291835 C54.8965238,7.84236055 53.8779305,7.84236055 53.2473727,8.47291835 L51,10.7041229 L48.7526273,8.47291835 C48.1220695,7.84236055 47.1034762,7.84236055 46.4729184,8.47291835 C45.8423605,9.10347615 45.8423605,10.1220695 46.4729184,10.7526273 L48.720291,13 L46.4729184,15.2473727 C45.8423605,15.8779305 45.8423605,16.8965238 46.4729184,17.5270816 L46.4729184,17.5270816 C47.1034762,18.1576395 48.1220695,18.1576395 48.7526273,17.5270816 L51,15.2958771 L53.2473727,17.5270816 C53.8779305,18.1576395 54.8965238,18.1576395 55.5270816,17.5270816 L55.5270816,17.5270816 C56.1576395,16.8965238 56.1576395,15.8779305 55.5270816,15.2473727 L53.2958771,13 L55.5270816,10.7526273 C56.1576395,10.1220695 56.1576395,9.10347615 55.5270816,8.47291835 Z"
                              id="Path"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </span>
          </div>
        ) : (
          <EmptyImage />
        )}

        <figure className="button-column">
          <span>
            {transparent && fileName ? fileName : null}
            {square
              ? "Image"
              : !(transparent && fileName) && label
                ? "Select File"
                : "Attach image"}
          </span>
          <span className="small-gray-text">
            {!!transparent
              ? "SVG, WEBP or transparent PNG under 1 MB"
              : square
                ? "Image should be square, 512x512 pixels minimum."
                : "JPEG, PNG, or JPG under 1 MB"}
          </span>
          <input {...getInputProps()} />
          <button
            className="custom-file-input"
            onClick={open}
            disabled={isLoading}
            type="button"
          ></button>
        </figure>
      </div>
      {errorMessage && showError && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default AddImage;
