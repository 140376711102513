import React, { useEffect, useState } from "react";
import successIcon from "../../../assets/images/icons/thumb_up_off_alt_black_24dp.svg";
import { SuccessToastProps } from "../../helpers/types";
import { cleanClassNames } from "../../helpers/classNames";
import "./success-toast.scss";

const SuccessToast = ({
  show,
  setShow,
  message,
  subMessage,
  className,
  icon,
  absolute = false,
}: SuccessToastProps) => {
  const [isHiding, setIsHiding] = useState(false);

  useEffect(() => {
    if (show) {
      setIsHiding(false);
      const timeout = setTimeout(() => {
        setIsHiding(true);
        setTimeout(() => setShow(false), 1000);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  if (!show && !isHiding) return null;

  return (
    <div
      className={cleanClassNames(
        "success-toast",
        className,
        isHiding ? "success-toast--hidden" : "",
      )}
      style={!absolute ? {} : { position: "fixed", left: "240px", top: "0px" }}
    >
      <div className="success-toast__wrapper">
        <div className="success-toast__content">
          <div className="success-toast__icon">
            <img
              src={icon || successIcon}
              className="success-toast__icon-image"
              alt="success"
            />
          </div>
          <div className="success-toast__message-container">
            <div className="success-toast__message">{message}</div>
            {subMessage && (
              <div className="success-toast__sub-message">{subMessage}</div>
            )}
          </div>
          <button
            type="button"
            className="success-toast__close-button"
            onClick={() => {
              setIsHiding(true);
              setTimeout(() => setShow(false), 1000);
            }}
          >
            <div className="success-toast__close-icon">x</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessToast;
