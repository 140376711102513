import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import downArrow from "../../../assets/images/icons/angle-down.svg";
import inkindLogo from "../../../assets/images/enterprise/inkind/icons/ocp-logo.png";
import useIsMobile from "../../hooks/useIsMobile";
import clipToSave from "./images/clip-to-save.svg";
import googleMobile from "./images/google-mobile.svg";
import appleMobile from "./images/apple-mobile.svg";
import appleDesktop from "../../../assets/images/checkout/ocp-app-store-refresh.png";
import googleDesktop from "../../../assets/images/checkout/ocp-google-store-refresh.png";
import offerCodeBg from "../../../assets/images/enterprise/inkind/icons/offercodeBG.jpg";
import offerCodeBgMobile from "../../../assets/images/enterprise/inkind/icons/offerbg-mobile.png";
import twoPhones from "./images/two-phones.png";
import useDeviceType from "../../hooks/useDeviceType";
import SuccessToast from "../../ui/molecules/SuccessToast";
import "./stylesheets/redeem-gifting.scss";

interface AppProps {
  redeemCode: string;
  qrCodeUrl: string;
  name: string;
  amount: string;
  locationCount: number;
  appStoreUrl: string;
  playStoreUrl: string;
}

const App: React.FC<AppProps> = ({
  redeemCode,
  qrCodeUrl,
  name,
  amount,
  locationCount,
  appStoreUrl,
  playStoreUrl,
}) => {
  const [copied, setCopied] = useState(false);
  const isMobile = useIsMobile();
  const scrollTo = (where: string) => {
    document?.querySelector(where)?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const { isApple } = useDeviceType();
  return (
    <div className="offers-main-container redeem-gifting">
      <div className="offers-main-content">
        <main className="offers-main">
          <div
            className="offers-brand-main-around"
            style={{
              backgroundImage: `url(${isMobile ? offerCodeBgMobile : offerCodeBg})`,
            }}
          >
            <div
              className="header-brand-img-container"
              style={{ flexDirection: "row" }}
            >
              <img src={inkindLogo} alt="inkind logo" />
            </div>
          </div>
          <div className="main-container">
            <SuccessToast
              message="Copied to clipboard."
              show={copied}
              setShow={setCopied}
            />
            <div className="grid-container">
              <div className="welcome-container">
                <div className="welcome-bonus-container">
                  <div className="welcome-bonus-top">
                    <p className="superheader">
                      {name
                        ? `${name.toUpperCase()} SENT YOU A GIFT`
                        : "A GIFT FOR YOU"}
                    </p>
                    <div className="offer-claim-header">
                      Claim your ${amount}
                    </div>
                  </div>
                  <div className="welcome-bonus-restrictions">
                    {!isMobile
                      ? "Enter the code below in the inKind app to easily accept your gift!"
                      : "Download the inKind app to easily accept your gift!"}
                  </div>
                  <div className="qr-code-row">
                    <div className="your-code">
                      <span className="highlight">YOUR CODE</span>
                      <span>{redeemCode}</span>
                    </div>
                    <CopyToClipboard
                      text={redeemCode}
                      onCopy={() => setCopied(true)}
                    >
                      <img
                        src={clipToSave}
                        alt="clip to save icon"
                        className="clip-to-save"
                      />
                    </CopyToClipboard>
                  </div>
                  <div className="divider-new"></div>
                </div>
                <div className="download-link-container">
                  <section className="download-link-body">
                    <div className="unlocked-offer-claim-container code-main">
                      <div className="download-links-desktop">
                        <div className="download-links-desktop-left">
                          <div>
                            <div className="sub-title">
                              Scan to claim in app
                            </div>
                            <div>
                              Download the inKind app and claim your gift to
                              spend at thousands of top restaurants.
                            </div>
                          </div>
                          <div className="code-container ">
                            <img
                              className="qrcode"
                              src={qrCodeUrl}
                              alt="qr-code"
                            />
                            <div className="download-app-btns">
                              <a
                                className="download-button apple-button"
                                href={appStoreUrl}
                                target="_blank"
                              >
                                <img src={appleDesktop} alt="app store" />
                              </a>
                              <a
                                className="download-button google-button"
                                href={playStoreUrl}
                                target="_blank"
                              >
                                <img src={googleDesktop} alt="play store" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <img
                          src={twoPhones}
                          alt="two phones"
                          className="two-phones"
                        />
                      </div>
                      <div className="download-links-mobile">
                        <div>
                          <div className="sub-title">Download inKind</div>
                          <div>
                            Download the inKind app and claim your gift to spend
                            at thousands of top restaurants.
                          </div>
                        </div>
                        {isApple ? (
                          <a
                            className="download-button"
                            href={appStoreUrl}
                            target="_blank"
                          >
                            <img src={appleMobile} alt="app store" />
                          </a>
                        ) : (
                          <a
                            className="download-button"
                            href={playStoreUrl}
                            target="_blank"
                          >
                            <img src={googleMobile} alt="google play" />
                          </a>
                        )}
                        <img
                          src={twoPhones}
                          alt="two phones"
                          className="two-phones"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div
              className="map-show-container"
              style={{
                display: !isMobile ? "flex" : "none",
              }}
            >
              <a
                className="map-show"
                onClick={(e) => {
                  scrollTo("#offers-map-section");
                }}
              >
                Explore locations
                <div className="map-show-arrow">
                  <img
                    src={downArrow}
                    alt="Scroll down"
                    className="down-arrow-offers bounce"
                  />
                </div>
              </a>
            </div>
          </div>
        </main>
      </div>
      {!isMobile ? (
        <div className="map-box map-box-dark" id="offers-map-section">
          <div className="pass-map-header">
            {locationCount}+ Restaurants, Bars & Cafés
          </div>
          <iframe
            src="/embed/map?pass=true&colorMode=black"
            className="inkind-map"
            title="map"
          />
        </div>
      ) : null}
    </div>
  );
};

export default App;
